/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  src: local('Circular Std'), url('CircularStd-Book.woff') format('woff');
}

@font-face {
  font-family: 'Circular Std';
  font-style: italic;
  font-weight: 400;
  src: local('Circular Std'), url('CircularStd-BookItalic.woff') format('woff');
}

@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  src: local('Circular Std'), url('CircularStd-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Circular Std';
  font-style: italic;
  font-weight: 500;
  src: local('Circular Std'),
    url('CircularStd-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  src: local('Circular Std'), url('CircularStd-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Circular Std';
  font-style: italic;
  font-weight: 700;
  src: local('Circular Std'), url('CircularStd-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 900;
  src: local('Circular Std'), url('CircularStd-Black.woff') format('woff');
}

@font-face {
  font-family: 'Circular Std';
  font-style: italic;
  font-weight: 900;
  src: local('Circular Std'), url('CircularStd-BlackItalic.woff') format('woff');
}
